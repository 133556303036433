@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/*--------------------------------------------------------------
# General Styles
--------------------------------------------------------------*/
html,
body {
  margin: 0;
  padding: 0;
  background: #f2f2f2;
  color: #343a40;
  font-family: "Roboto", sans-serif !important;
}

a {
  color: #fe7c18;
}

a:hover {
  color: #6c757d;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #2d3339;
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
# Text and buttons
--------------------------------------------------------------*/

.text-orange {
  color: #fe7c18 !important;
}

.text-gray {
  color: #9299a0 !important;
}

.text-gray-500 {
  color: #6c757d !important;
}

.text-danger {
  color: #ff4d6b !important;
}

.text-success {
  color: #6eb300 !important;
}

.small-text{
  color: #ff4d6b !important;
  font-size:12px ;
}
.btn-orange {
  background-color: #fe7c18;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  min-width: 150px;
  padding: 0.7rem 2rem;
  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

.btn-orange-light{
  background-color: #ffe2cc ;
  border: none;
  float: right;
  color:#fe7c18;
  &:hover {
    background-color: #fe7c18;
    cursor: pointer;
    color: #fff;
  }
  }

  .btn-green{
    background-color: #6eb300 ;
    border: none;
    float: right;
    color:#fff;
    &:hover {
      background-color: #000;
      cursor: pointer;
      color: #fff;
    }
    }

.btn-orange-light-spoiler{
  background-color: #ffe2cc ;
  border: none;
  color:#fe7c18;
  &:hover {
    background-color: #fe7c18;
    cursor: pointer;
    color: #fff;
  }
}

.paddingthird{
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 2%;
}

.btn-input-orange{
  color: #fff;
  background-color: #fe7c18;
  &:hover {
      background-color: #000;
      cursor: pointer;
      color: #fff;
  }
}

.btn-red {
  background-color: #ff4d6b;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  min-width: 150px;
  padding: 0.6rem 1.8rem;
  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

.btn-orange-modal {
  background-color: #fe7c18;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  min-width: 80px;
  padding: 0.6rem 1.8rem;
  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

.btn-gray {
  background-color: #6c757d;
  color: #fff;
  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 226 204 / 25%) !important;
}

.icon-success {
  width: 80px;
  margin: 0 auto;
  display: block;
}

.btn-cancel{
  background-color: #6c757d ;
  border: none;
  color:#fff;
  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

.btn-guardar{
  background-color: #fe7c18 ;
  border: none;
  color:#fff;
  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

/*--------------------------------------------------------------
# Card
--------------------------------------------------------------*/

.card {
  border: 1px solid #ebedf2;
}

.card .card-body {
  padding: 20px 30px;
}

.card .card-title {
  color: #313131;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.page-header {
  padding: 0.75rem 1.25rem;
  background-color: #29353b;
  border-bottom: 1px solid #e3e6f0;
}

.grid-margin {
  margin-bottom: 30px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #29353b;
  border-bottom: 1px solid #e3e6f0;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ffe2cc !important;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 7px solid#fe7c18;
  border-top-color: #ffe2cc;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 992px) {
  .push-right {
    .sidebar {
      left: 230px !important;
    }
  }
}

/*--------------------------------------------------------------
# Table
--------------------------------------------------------------*/

.table-responsive>.table-bordered {
  border: 0;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #ebedf2;
}

.table td, .table th {
  padding: .9375rem;
  border-top: 1px solid #ebedf2;
}

.table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 600;
  font-size: 16px;
  background-color: #f0f1f5;
  line-break: unset;
  white-space: nowrap;
}

.table td, .table th {
  vertical-align: middle;
  font-size: 16px;
  line-height: 1;
}

.table tbody {
  white-space: nowrap;
}


@media screen and (max-width: 767px) {
  .table {
    font-size: 14px;
  }

  .table thead th {
    font-size: 14px;
  }

  .table td, .table th {
    font-size: 14px;
  }

  .btn-orange, .btn-gray {
    width: 100%;
    min-width: unset;
  }
}

/* TABLE PAGINATION*/

.my-pagination .ngx-pagination .current {
  background: #fe7c18;
}
